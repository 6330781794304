import React, { useContext, useEffect, useState } from 'react'
import SideBar from '../Component/DashboardComponent/SideBar'
import TopBar from '../Component/DashboardComponent/TopBar'
import Content from '../Component/DashboardComponent/Content'
import MailModal from '../Component/DashboardComponent/Emails/Modal'
import axios from 'axios'
import { AuthContext } from '../Component/contexts/AuthContext'
import { MailContext } from '../Component/contexts/MailContext'
import { DataContext } from '../Component/contexts/DataContext'
import Loader from '../Component/loader'

function Dashboard() {

  const { sidebarIndex, expand, setExpand, setMobileView } = useContext(AuthContext)

  const { setMailModal, modal } = useContext(MailContext)

  const { setInboxData } = useContext(DataContext)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    
  }, [])

  useEffect(() => {

    // console.log(document.body.offsetWidth, 'ss')

      (async () => {

          await setInboxData()

          setIsLoading(false)


      })()
  }, [])

  useEffect(() => {
      if (!isLoading) {
          if (document.body.offsetWidth < 994) {
          setExpand(true)
        }

        if (document.body.offsetWidth < 801) {
          setMobileView(true);
        }

    }
  }, [isLoading])


  return (
    <>
    {isLoading ? <Loader /> : <div className='flex dash h-screen overflow-hidden'>
        <aside style={{
          position: expand ? 'absolute' : '',
          minWidth: expand ? '60px' : '300px',
          width: expand ? '60px' : '20%',
        }} className={`fixed bd1:w-[60px] bd1:min-w-[60px] w-[20%] ob1:!min-w-[200px] bg-white z-30`}>
          <SideBar openModal={() => setMailModal(true)} />
      </aside>
        <main className={`w-full bd1:pl-[60px] flex-1 pl-[20%] ${modal ? 'bg-[#D9D9D9]' : ''}`}>
        <TopBar />
        <div className='mt-[89px] z-0 h-[calc(100vh-89px)]'>
          <Content />
        </div>
      </main>
        <MailModal isOpen={modal} onClose={() => setMailModal(false)} />
    </div>}
    </>
  )
}

export default Dashboard