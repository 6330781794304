// EmailDetail.js
import React, { useContext, useEffect, useState } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { MailContext } from '../../contexts/MailContext';
import { HiReply } from 'react-icons/hi'
import { Button, IconButton, Modal, Tooltip, Box, CircularProgress } from '@mui/material';
import { MdArrowBack, MdOutlineForwardToInbox } from 'react-icons/md';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { AuthContext } from '../../contexts/AuthContext';
import { BiEditAlt } from 'react-icons/bi';
import { FaTrashRestore } from 'react-icons/fa';
import { ContentState, EditorState, convertFromHTML } from "draft-js"
import parse from "html-react-parser";
import { Link } from 'react-router-dom';
import { DataContext } from '../../contexts/DataContext';
import { FiX } from 'react-icons/fi';
import axios from 'axios';
import toast from 'react-hot-toast';


function EmailDetail({ email }) {

  const { setSubject: setSub, setMessage, setMailModal, modal, setReply, setEmails, setComSoon, setDocs, setDraftId, selectedEmail,setSelectedEmail } = useContext(MailContext)

  const { setSentData, setInboxData, setDraftData, setTrashData, setImportData } = useContext(DataContext)

  const { sidebarIndex } = useContext(AuthContext)

  const [update, setUpdate] = useState(modal)

  const [impLoader, setImpLoader] = useState(false);

  const [conDelete, setConDelete] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [conRecover, setConRecover] = useState(false);

  const [recoLoading, setRecoLoading] = useState(false);


  const user = JSON.parse(localStorage.getItem('token'))

  useEffect(() => {
    setMailModal(update)
  }, [update, setMailModal])

  useEffect(() => {
    setUpdate(modal)
  }, [modal])

  const recoverEmail = async () => {

    setRecoLoading(true)
    try {

      setSelectedEmail(null)

      await axios.post('/trash/recover', { id: email.messageIndex, email: user.email, password: user.pass });

      await setTrashData()

      toast.success('Email recovered successfully')
      setRecoLoading(false)

    } catch (err) {
      toast.error('Something went wrong, Please try again later')
      setRecoLoading(false)
    }

  }

  const Important = async (bool) => {

    setImpLoader(true)

    const tabs = [
      "INBOX",
      "Sent", , email.folder
    ];

    try {

      await axios.post(bool ? '/important' : '/important/remove', { id: email.messageIndex, email: user.email, password: user.pass, tab: tabs[sidebarIndex] });

      if (sidebarIndex === 0) await setInboxData()

      if (sidebarIndex === 1) await setSentData()

      if (sidebarIndex === 3) await setImportData()

      toast.success(bool ? 'Email added to important successfully' : 'Email removed from important successfully')

      email.important = bool;

      setImpLoader(false)

    } catch (err) {
      toast.error('Something went wrong, Please try again later')
      setImpLoader(false)
    }
  }

  const deleteEmail = async (id) => {

    try {

      setSelectedEmail(null)

      const tabs = [
        "INBOX",
        "Sent",
      ];

      if (sidebarIndex === 2) {
        await axios.post(`/draft/delete/${id}`, { id, email: user.email, password: user.pass, "_method": "DELETE" });
      } else {
        await axios.post('/trash', { tab: tabs[sidebarIndex], id, email: user.email, password: user.pass });
      }

      if (sidebarIndex === 0) {
        await setInboxData()
      } else if (sidebarIndex === 1) {
        await setSentData()
      } else if (sidebarIndex === 2) {
        await setDraftData()
      }

      toast.success("Email deleted successfully")

      setDeleteLoading(false);

      setConDelete(false)

    } catch (err) {
      toast.error('Something went wrong, Please try again later')

      setDeleteLoading(false);
    }

  }

  const editDraft = () => {

    setDraftId(email.messageIndex)
    setSub(email.subject)
    setMessage(() => EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(email.message)
      )
    ))
    setEmails(JSON.parse(email?.to || '[]'))
    setDocs(email?.attachments || [])
    setUpdate(true)
  }

  const forwardEmail = () => {
    setSub(`Fwd:${email.subject}`)

    setMessage(() => EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(`<p>...</p><br /><br /><br />\n
            <p><br></p>
            <p>-------- Forwarded message --------</p>
            <p>From: ${email.sender}</p>
            <p>Date: ${email.Date}, ${email.time}</p>
            <p>Subject: ${email.subject}</p>
            <p>To: ${user.email}</p>
            <p><br></p>
            <p>${email.mailText}</p>`)
      )
    ))


    setUpdate(true)
  }

  const replyEmail = () => {
    setSub(email.subject.indexOf('Re:') === 0 ? email.subject : `Re:${email.subject}`)
    setReply(email.messageId)
    setEmails(typeof email.senderemail == 'string' ? email.senderemail.split(',') : email.senderemail)
    setUpdate(true)
  }



  return (
    <div className='mx-5 my-5'>
      {selectedEmail && <Button onClick={() => setSelectedEmail(null)} className="!hidden  bd2:!flex !bg-[#e9e9e962] !py-1 !items-center !justify-center !cursor-pointer !w-fit !rounded-lg !mb-3 !font-aeonikmedium !normal-case !text-sm !text-[#808080]">
        <MdArrowBack
          color={"text-[#808080]"}
          className="relative mr-1"
          size={20}
        />

        Go back
      </Button>}
      <Modal open={conDelete} onClose={() => setConDelete(false)}>
        <div className="w-screen cusscroller overflow-y-scroll overflow-x-hidden absolute h-screen flex items-center bg-[#ffffffb0]">
          <div className="2usm:px-0 mx-auto max-w-[500px] 2usm:w-full relative w-[85%] usm:m-auto min-w-[340px] px-6 my-8 items-center">
            <div className="rounded-lg bg-white shadow-lg shadow-[#cccccc]">
              <div className="border-b flex justify-between items-center py-[9px] px-[17px] text-[16px] font-[600]">
                Delete Message?
                <IconButton onClick={() => setConDelete(false)} size={"medium"}>
                  <FiX
                    size={20}
                    className="cursor-pointer"

                  />
                </IconButton>
              </div>
              <div className="form relative">
                <Box sx={{ width: "100%", padding: "15px 24px" }}>
                  <span className="text-[#7c7c7c] mt-3 block font-[500] text-[16px] text-center ">
                    {"Are you sure you want to delete message?"}
                  </span>

                  <div className="flex justify-evenly mt-[20px]">

                    <Button
                      className="!bg-[#e8e8e8] !text-[#7c7c7c] !font-[inherit] !normal-case
                          !rounded-lg !px-[20px] !py-[10px] !text-[14px] !font-[500] hover:!bg-[#d8d8d8]"
                      onClick={async () => {
                        if (deleteLoading) return;

                        setDeleteLoading(true);

                        await deleteEmail(email.messageIndex);


                      }}
                    >
                      {deleteLoading ? (
                        <>
                          <div className="mr-3 h-[20px] text-[#7c7c7c]">
                            <CircularProgress
                              color={"inherit"}
                              className="!w-[20px] !h-[20px]"
                            />
                          </div>{" "}
                          <span>Just a Sec...</span>
                        </>
                      ) : (
                        <>Yes, Delete</>
                      )}
                    </Button>

                    <Button
                      className="!bg-[#e8e8e8] !text-[#7c7c7c] !font-[inherit] !normal-case
                          !rounded-lg !px-[20px] !py-[10px] !text-[14px] !font-[500] hover:!bg-[#d8d8d8]"
                      onClick={() => setConDelete(false)}
                    >
                      No
                    </Button>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={conRecover} onClose={() => setConRecover(false)}>
        <div className="w-screen cusscroller overflow-y-scroll overflow-x-hidden absolute h-screen flex items-center bg-[#ffffffb0]">
          <div className="2usm:px-0 mx-auto max-w-[500px] 2usm:w-full relative w-[85%] usm:m-auto min-w-[340px] px-6 my-8 items-center">
            <div className="rounded-lg bg-white shadow-lg shadow-[#cccccc]">
              <div className="border-b flex justify-between items-center py-[9px] px-[17px] text-[16px] font-[600]">
                Recover Message?
                <IconButton onClick={() => setConRecover(false)} size={"medium"}>
                  <FiX
                    size={20}
                    className="cursor-pointer"

                  />
                </IconButton>
              </div>
              <div className="form relative">
                <Box sx={{ width: "100%", padding: "15px 24px" }}>
                  <span className="text-[#7c7c7c] mt-3 block font-[500] text-[16px] text-center ">
                    {"Are you sure you want to recover message?"}
                  </span>

                  <div className="flex justify-evenly mt-[20px]">

                    <Button
                      className="!bg-[#e8e8e8] !text-[#7c7c7c] !font-[inherit] !normal-case !rounded-lg !px-[20px] !py-[10px] !text-[14px] !font-[500] hover:!bg-[#d8d8d8]"
                      onClick={async () => {
                        if (recoLoading) return;

                        await recoverEmail();

                      }}
                    >
                      {recoLoading ? (
                        <>
                          <div className="mr-3 h-[20px] text-[#7c7c7c]">
                            <CircularProgress
                              color={"inherit"}
                              className="!w-[20px] !h-[20px]"
                            />
                          </div>{" "}
                          <span>Just a Sec...</span>
                        </>
                      ) : (
                        <>Yes, Recover</>
                      )}
                    </Button>

                    <Button
                      className="!bg-[#e8e8e8] !text-[#7c7c7c] !font-[inherit] !normal-case
                          !rounded-lg !px-[20px] !py-[10px] !text-[14px] !font-[500] hover:!bg-[#d8d8d8]"
                      onClick={() => setConRecover(false)}
                    >
                      No
                    </Button>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="flex items-center space-x-4 ">
        <img src={email.profileIcon} alt="Profile" className='w-9 h-9 rounded-full' />
      <div className='flex justify-between items-center w-full smd:flex-wrap'>
        <div className=''>
            <h1 className='font-aeonikmedium text-sm'>{email.sender}</h1>
            <p className='font-aeonikregular text-xs'>{email.senderemail}</p>
        </div>
        <div className='flex items-center space-x-2 mt-[5px]'>
          <span className='font-aeonikregular text-xs'>{email.time}</span>
          <span className="text-[#969696]">·</span>
          <span className='font-aeonikregular text-xs'>{email.Date}</span>
        </div>
      </div></div>

      <div className='flex items-start justify-between m2:mt-5 mt-10 m2:flex-col-reverse'>
        <div>
          <span className='text-xs bg-[#F2F2F2] rounded-md py-1 px-3 font-aeonikregular'>Subject</span>
        </div>

        <div className='flex items-center m2:my-2 space-x-4'>
          {(() => {

            if (sidebarIndex === 1 || sidebarIndex === 0 || sidebarIndex === 3) {

              return <><Tooltip title="Add to important" arrow>
                <IconButton onClick={() => Important(!email.important)} className='cursor-pointer text-[#808080]' size={"medium"}>
                  {impLoader ?
                    <CircularProgress color="inherit" size={19} /> : !email.important ? <AiOutlineStar size={19} color='#808080' /> : <AiFillStar size={19} color='#808080' />}

                </IconButton>
              </Tooltip>

                <Tooltip arrow title="Reply Mail">
                  <IconButton onClick={replyEmail} className='cursor-pointer' size={"medium"}><HiReply size={19} color='#808080' /></IconButton></Tooltip>

                <Tooltip arrow title="Forward Mail">
                  <IconButton onClick={forwardEmail} className='cursor-pointer' size={"medium"}><MdOutlineForwardToInbox size={19} color='#808080' /></IconButton></Tooltip>

                <Tooltip title="Delete" arrow>
                  <IconButton onClick={() => setConDelete(true)} className='cursor-pointer' size={"medium"}> <BsTrashFill size={19} color='#808080' /></IconButton></Tooltip></>

            } else if (sidebarIndex === 2) {
              return <>
                <Tooltip title="Edit" arrow>
                  <IconButton onClick={() => editDraft()} className='cursor-pointer' size={"medium"}><BiEditAlt size={19} color='#808080' /></IconButton></Tooltip>

                <Tooltip title="Delete" arrow>
                  <IconButton onClick={() => setConDelete(true)} className='cursor-pointer' size={"medium"}> <BsTrashFill size={19} color='#808080' /></IconButton></Tooltip></>

            } else if (sidebarIndex === 4) {
              return <></>
            } else if (sidebarIndex === 5) {
              return <>

                <Tooltip arrow title="Restore">
                  <IconButton onClick={() => setConRecover(true)} className='cursor-pointer' size={"medium"}> <FaTrashRestore size={19} color='#808080' /></IconButton></Tooltip>

              </>
            }

          })()}

        </div>
      </div>

      <h2 className='font-aeonikmedium text-base mt-2'>{email.subject}</h2>

      <div className='mt-4 font-aeonikregular text-sm'>{email.mailHtml}</div>

      {Boolean(email?.attachments.length) && <div style={{
        gridTemplateColumns: "repeat(auto-fill, minmax(140px, 1fr))"
      }} className="grid gap-3 items-end mt-4">
        {sidebarIndex == 2 ? email.attachments.map((e, index) => (
          <Link key={index} target="_blank" to={e}>
            <div className="flex items-center cursor-pointer flex-col">
              <div className="w-full py-4 text-white bg-[#199976] px-2 truncate">
                <span className="text-[14px] font-aeonikbold font-[600] ">{e}</span></div>
            </div>
          </Link>
        )) : email.attachments.map(({ path, name, subtype }, index) => (
          <Link key={index} target="_blank" to={path}>
            <div className="flex items-center cursor-pointer flex-col">
              {['jpeg', 'jpg', 'png', 'svg'].includes(subtype?.toLowerCase()) && <div className="max-h-[80px] overflow-hidden w-full"><img src={path} alt="Attachment" className="w-full" /></div>}
              <div className="w-full py-4 text-white bg-[#199976] px-2 truncate">
                <span className="text-[14px] font-aeonikbold font-[600] ">{name}</span></div>
            </div>
          </Link>
        ))}
      </div>}
    </div>
  );
}

export default EmailDetail;