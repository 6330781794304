import { createContext, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

export const DataContext = createContext({

})


let timerunner = null


export const DataProvider = ({ children }) => {    

    const [inboxData, setInboxDataMain] = useState([])

    const [pLoading, setLoading] = useState(false)

    const [sentData, setSentDataMain] = useState([])

    const [draftData, setDraftDataMain] = useState([])

    const [importData, setImportDataMain] = useState([])

    const [trashData, setTrashDataMain] = useState([])

    const setInboxData = async (bool = false) => {

        clearTimeout(timerunner)

        const token = JSON.parse(localStorage.getItem('token') || '{}')

        if (!token?.email) return;

        try {

            if (bool) setLoading(true)

            const res = await axios.post('/retrieve/mails', {
                email: token.email,
                password: token.pass,
                tab: 'INBOX'
            })

            if (bool) setLoading(false)

            timerunner = setTimeout(setInboxData, 4000)

            setInboxDataMain(res.data.mails)

        } catch (err) {
            console.log(err)

            timerunner = setTimeout(setInboxData, 4000)

            return false;
        }
    }


    const setImportData = async (bool = true) => {
            
            clearTimeout(timerunner)
    
            const token = JSON.parse(localStorage.getItem('token') || '{}')
    
            if (!token?.email) return;
    
            try {
    
                setLoading(true)
    
                const res = await axios.post('/retrieve/important', {
                    email: token.email,
                    password: token.pass,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
    
                setLoading(false)
    
                setImportDataMain(res.data.important)
    
            } catch (err) {
                console.log(err)
                setLoading(false)
                toast.error('Error Fetching Data, Please try again')
                // if (bool) setDraftData(); 
                return false;
            }
    }

    const altTabs = async (tab, bool = true) => {

        const token = JSON.parse(localStorage.getItem('token') || '{}')

        if (!token?.email) return;

        try {

            setLoading(true)

            const res = await axios.post('/retrieve/mails', {
                email: token.email,
                password: token.pass,
                tab
            })

            setLoading(false)

            switch (tab) {
                case 'Drafts': 
                    setDraftDataMain(res.data.mails)
                break;
                case 'Trash': 
                    setTrashDataMain(res.data.mails)
                    break;
                case 'Sent':
                    setSentDataMain(res.data.mails)
                    break;
                default:
                    return res.data.mails
            }

        } catch (err) {
            console.log(err, tab)
            toast.error('Error Fetching Data, Retrying...')
            if (bool) altTabs(tab);
            setLoading(false)
            return false;
        }
    }

    const searchTab = async (tab, search) => {

        if (!search) {
            if (['Trash', 'Sent', 'Drafts'].includes(tab)) {
                await altTabs(tab);
            }else if (tab === 'INBOX') {
                await setInboxData()
            }else if (tab === 'Important') {
                await setImportData()
            }

            return;
        };

        if (timerunner) clearTimeout(timerunner)

        const token = JSON.parse(localStorage.getItem('token') || '{}')

        if (!token?.email) return;

        try {

            setLoading(true)

            if (tab === 'Important') {
                    
                const res = await axios.post('/retrieve/important', {
                    email: token.email,
                    password: token.pass,
                    search
                })

                setImportDataMain(res.data.important)
                
            }else{

                const res = await axios.post('/retrieve/mails', {
                    email: token.email,
                    password: token.pass,
                    tab,
                    search
                })


                switch (tab) {
                    case 'Drafts':
                        setDraftDataMain(res.data.mails)
                        break;
                    case 'Trash':
                        setTrashDataMain(res.data.mails)
                        break;
                    case 'Sent':
                        setSentDataMain(res.data.mails)
                        break;
                    default:
                        setInboxDataMain(res.data.mails)
                }
        }

            setLoading(false)   

        } catch (err) {
            toast.error('Error Fetching Data')
            console.log(err, tab)
            setLoading(false)
            return false;
        }

    }

    return (
        <DataContext.Provider value={{
            inboxData, setInboxData, pageLoading: pLoading, sentData, setSentData: (bool = true) => altTabs('Sent', bool), importData, setImportData, draftData, setDraftData: (bool = true) => altTabs('Drafts', bool), trashData, setTrashData: (bool = true) => altTabs('Trash', bool), searchTab
        }}>
            {children}
        </DataContext.Provider>
    )

}