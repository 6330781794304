import React, { useContext, useEffect, useState } from 'react';
import EmailItem from './EmailItem';
import EmailDetail from './EmailDetails';
import emptymail from '../../../Assets/emptymail.png';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import { Button, CircularProgress, IconButton, List, Tooltip } from '@mui/material';
import { MdArrowBack, MdHourglassEmpty, MdRefresh } from 'react-icons/md';
import { DataContext } from '../../contexts/DataContext';
import { MailContext } from '../../contexts/MailContext';

function Mailbox({ emails }) {


  const { sidebarIndex, mobileView } = useContext(AuthContext)

  const { search, selectedEmail, setSelectedEmail } = useContext(MailContext)

  const { setInboxData, pageLoading, setSentData, setDraftData, setTrashData, searchTab, setImportData } = useContext(DataContext)


  const token = JSON.parse(localStorage.getItem('token'))

  useEffect(() => { 
    setSelectedEmail(null)
  }, [sidebarIndex])

  const refresh = () => {

    if (pageLoading) return;

    if (!search.length) {
    switch (sidebarIndex) {
      case 2:
        setDraftData()
        break;
      case 1: 
        setSentData()
        break;
      case 5:
        setTrashData()
        break;
      case 3:
        setImportData()
       break;
      default:
        setInboxData(true)
    }
  }else{
      searchTab();
  }
  }
  

  return (
    <div className="flex h-full items-start bg-white">
      {((!selectedEmail && mobileView) || !mobileView) && <div className="w-[30%] h-[calc(100vh-140px)] min-w-[260px] bd2:w-full relative">
        <div className='flex items-center justify-between px-4 pb-3 min-h-[63px] pt-4'>
          <h1 className='text-sm font-aeonikmedium text-[#425466]'>Messages</h1>
          <Tooltip title="Refresh" arrow><IconButton onClick={refresh} size={'medium'} className='cursor-pointer'>
            <MdRefresh color='#808080' size={19} />
          </IconButton></Tooltip>
        </div>
        
        {pageLoading && <div style={{
          background: 'linear-gradient(rgba(255, 255, 255, .9) 50%, transparent)'
        }} className="absolute w-full text-[#199976] min-h-[100px] z-10 bg-[#ffffff8a] flex items-center justify-center">
          <CircularProgress size={24} color='inherit'/>
        </div>}
        {!emails.length ? <div className="h-full flex px-1 flex-col justify-center items-center ">
          <MdHourglassEmpty size={26} className="text-[#121212]"/>
          <h1 className='text-[15px] font-aeonikregular text-[#121212] text-center mt-4'>Its empty here for now</h1>
        </div> : <List className="!h-[100%] cusscroller !overflow-y-scroll">
        {emails.map((emx, i) => (
          <EmailItem
            key={i}
            isSelected={selectedEmail?.id === emx.id}
            em={emx}
            onSelect={() => {

              setSelectedEmail(emx); 

              if (sidebarIndex !== 0) return;

              if (Number(emx.seen)) return;


              const data = {
                email: token.email,
                password: token.pass,
                id: emx.messageIndex
              }

              axios.post('/read/messages', data, {
                headers: {
                  'Content-Type': 'application/json'
                }
              });

            }}
          />
        ))}
        </List>}
      </div>}

      {((selectedEmail && mobileView) || !mobileView) && <div className="w-full overflow-scroll cusscroller border-l border-[#f2f2f2] break-words h-full">
        
        {sidebarIndex === 5 && <div className="w-full p-2 text-white bg-[#199976] truncate font-bold font-aeonikbold text-center text-[14px]"> Trash is emptied every 30days </div>}
        {selectedEmail ? (
          <EmailDetail email={selectedEmail} />
        ) : (
          <div className='flex flex-col items-center justify-center text-center mt-32'>
            <img src={emptymail} alt='empty-mails' className='' />
            <h1 className='text-base font-aeonikmedium mt-6'>Initiate a conversation</h1>
            <p className='text-xs font-aeonikregular text-[#808080] mt-3 w-[60%]'>Simply tap on any message to instantly open up a dialogue. Enjoy effortless engagement and stay connected with ease."</p>
          </div>
        )}
      </div>}
    </div>
  );
}

export default Mailbox;