import { CiFolderOn } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import React, { useContext, useState } from 'react';
import MailDescription from "./MailDescription";
import axios from "axios"
import toast from "react-hot-toast";
import { MailContext } from "../../contexts/MailContext";
import { Button, Modal } from "@mui/material";
import { stateToHTML } from 'draft-js-export-html'
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import validator from 'validator'


function MailModal({ isOpen, onClose }) {

    const { subject, setSubject: setSub, message, setMessage, emails, setEmails, setReply, reply, docs, draftId, setDraftId } = useContext(MailContext)

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(0)
    const [documents, setDocuments] = useState([
        ...docs
    ]);

    const removeDocument = (index) => {
        setDocuments(prevDocs => prevDocs.filter((_, docIndex) => docIndex !== index));
    };

    const reset = () => {
        setEmails([]);
        setMessage(() => EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(`<p></p>`)
            )
        ))
        setSub('')
        setDraftId("")
    }
    

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter' && inputValue) {
            if (!emails.includes(inputValue.trim())) setEmails(prevEmails => [...prevEmails, inputValue.trim()]);
            setInputValue('');
        }
    };

    const handleRemoveEmail = (indexToRemove) => {
        setEmails(prevEmails => prevEmails.filter((_, index) => index !== indexToRemove));
    };

    const saveDraft = async () => {
        
        const user = JSON.parse(localStorage.getItem('token'));

        if (!message.getCurrentContent().getPlainText().length && !documents.length && !subject) return

        const formData = new FormData();

        formData.append('email', user.email);
        
        formData.append('password', user.pass);

        if (message.getCurrentContent().getPlainText().length) formData.append('message', stateToHTML(message.getCurrentContent()));

        if (subject) formData.append('subject', subject);

        if (documents.length) {
            documents.forEach((doc) => {    
               if (typeof doc != 'string') formData.append(`documents[]`, doc);
               
               if (typeof doc == 'string') formData.append(`docuString[]`, doc); 
            });
        }

        setReply('')

        
        if (emails.length) emails.forEach(e => {
            formData.append('to[]', e);
        })

        try {

        if (draftId) {

            formData.append('_method', "PATCH");

            await axios.post(`/draft/update/${draftId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            });

        }else {

            await axios.post('/draft/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            });

            toast.success('Added to Draft')
        }

        reset()
        
        }catch (err) {
            reset()
        }

    }

    const handleSend = async () => {

        if (inputValue) {
            if (!emails.includes(inputValue.trim())) setEmails(prevEmails => [...prevEmails, inputValue.trim()]);
            setInputValue('');
        }

        const user = JSON.parse(localStorage.getItem('token'));

        if (loading) return;

        if ((!message.getCurrentContent().getPlainText().length && !documents.length) || !subject || !emails.length) return toast.error('Please fill all the fields');

        if (!emails.every(e => validator.isEmail(e.trim()))) return toast.error('Please ensure all receipient emails are valid');

        const toasty = toast.loading('Sending mail...');

        const formData = new FormData();

        formData.append('email', user.email);

        formData.append('password', user.pass);

        formData.append('message', stateToHTML(message.getCurrentContent()));

        emails.forEach(e => {
            formData.append('to[]', e);
        })

        formData.append('subject', subject);

        formData.append('originalMessageId', reply);

        formData.append('draftId', draftId);

        documents.forEach((doc) => {
            formData.append(`documents[]`, doc);
        });

        try {

        await axios.post('/send/message', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            onUploadProgress: (progressEvent) => {

                const { loaded, total } = progressEvent;

                const percent = Math.floor((loaded * 100) / total);

                setLoading(percent);

            },
            timeout: 0
        });

        toast.dismiss(toasty)

        toast.success("Message sent successfully")

        // You can also clear the message after sending
            setMessage(() => EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(`<p></p>`)
                )
                ))
            setSub("");
            setLoading(0)
            onClose();
            setReply('')
            setDraftId('')
            setEmails([]);

        }catch (err) {
            toast.dismiss(toasty)
            setLoading(0)
            toast.error(err.response?.data?.message || "Something went wrong, please try again")
        }
    };

    if (!isOpen) return null;

    const addDocument = async (file) => {
        setDocuments([...documents, file]);
    };

    return (
        <Modal 
        open={isOpen}
        sx={{
            "&& .MuiBackdrop-root": {
                backdropFilter: "blur(5px)",
                width: "calc(100% - 8px)",
            },
        }}
        onClose={() => false}
        className="overflow-y-scroll overflow-x-hidden cusscroller">

            <div className="w-screen h-screen flex items-center justify-center bg-transparent">

            <div className="bg-white min-w-[300px] py-5 smd:w-[90%] m2:w-[96%] m3:w-full rounded-xl w-[70%] h-fit m-auto">
                {/* Modal Content */}
                <div className="flex justify-between items-center px-5">
                    <h1 className="font-aeonikmedium text-lg">New Message</h1>
                    <div className="flex items-center space-x-4">
                        {/* <div className="flex items-center space-x-2">
                            <CiFolderOn size={20} />
                            <button className="cursor-pointer">
                                <h1 className="text-sm font-aeonikmedium">Save as draft</h1>
                            </button>
                        </div> */}

                        <Button onClick={() => {
                            onClose();
                            saveDraft()
                        }} className="!cursor-pointer !normal-case !text-sm !font-aeonikmedium !text-[#121212]">Close
                        </Button>
                    </div>
                </div>
                <div className="h-0.5 bg-[#f6f6f6] mt-3"></div>
                <div className="px-5 flex items-center space-x-2 mt-4">
                    <p className="font-aeonikregular text-[#5e5e5e] text-sm min-w-[60px]">Recipient :</p>

                    <div className="flex flex-wrap items-center w-[70%]">
                        {emails.map((email, index) => (
                            <span key={index} className="bg-[#F2F2F2] py-2 px-4 rounded-full m-1 flex items-center space-x-2">
                                <p className="font-aeonikregular text-sm text-[#425466]">{email}</p>
                                <button
                                    onClick={() => handleRemoveEmail(index)}
                                    className=" bg-[#fff] rounded-full h-6 w-6 items-center justify-center flex text-center text-sm font-aeonikmedium cursor-pointer"
                                >
                                    <AiOutlineClose strokeWidth={1} />
                                </button>
                            </span>
                        ))}
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyPress}
                            onBlur={() => {

                                if (!inputValue.length) return;

                               if (!emails.includes(inputValue.trim())) setEmails(prevEmails => [...prevEmails, inputValue.trim()]);
                                
                                setInputValue('');

                            }}
                            placeholder="Enter recipient e-mail address"
                            className="m-1 flex-grow font-aeonikregular text-sm outline-none"
                        />
                    </div>
                </div>
                <div className="h-0.5 bg-[#f6f6f6] mt-3"></div>
                <div className="px-5 flex items-center space-x-4 mt-4 w-[100%]">
                    <p className="font-aeonikregular text-[#5e5e5e] text-sm min-w-[60px]">Subject :</p>
                    <input placeholder="Mail Subject" value={subject} onChange={(e) => setSub(e.target.value)} inputMode="text" className="text-base font-aeonikregular outline-none w-[80%]" />
                </div>
                <div>
                    <MailDescription
                        documents={documents}
                        addDocument={addDocument}
                        removeDocument={removeDocument}
                        message={message}
                        loading={loading}
                        setMessage={setMessage}
                        onSend={handleSend}
                    />
                </div>
              </div>
            </div>
        </Modal>
    );
}


export default MailModal;