import React, { useEffect } from 'react'
import { CiSearch } from "react-icons/ci";
import { PiUserBold } from "react-icons/pi";
import { BiChevronDown, BiChevronUp, BiLogOutCircle } from "react-icons/bi";
import { MailContext } from '../contexts/MailContext';
import { AuthContext } from '../contexts/AuthContext';
import SwipeableViews from "react-swipeable-views";
import TabPanel from "../TabPanel";
import { DataContext } from '../contexts/DataContext';
import { PinField } from "react-pin-field";
import { List, ListItemButton, ListItem, ListItemIcon, ListItemText, Box, Modal, IconButton, Button, CircularProgress, TextField, Avatar, Tooltip, InputAdornment } from '@mui/material';
import { CgProfile } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom';
import { MdClose, MdDehaze, MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md'
import axios from 'axios'
import picture from '../../Assets/user.png'
import toast from 'react-hot-toast';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

function TopBar() {

    const navigator = useNavigate()

    const user = JSON.parse(localStorage.getItem('token'));

    const [viewPass, setViewPass] = React.useState(false)

    const [toggle, setToggle] = React.useState(false)

    const [pin, setPin] = React.useState('')

    const [pinsVisibility, setPinVisibility] = React.useState(false)

    const [isLoading, setLoading] = React.useState(false)

    const [page, setPage] = React.useState(0);

    const [modal, setModal] = React.useState(false)

    const [password, setPassword] = React.useState(user?.pass || '')

    const [altEmail, setAltEmail] = React.useState(user?.alt_email || '')

    const { search, setSearch, setComSoon, comeSoon } = React.useContext(MailContext)

    const { sidebarIndex, expand, setExpand } = React.useContext(AuthContext)

    const { searchTab } = React.useContext(DataContext)

    const [username, setUsername] = React.useState(user?.name || '')

    const [dp, setDp] = React.useState(user?.dp || '')

    const [image, setImage] = React.useState()

    const tabArr = ['INBOX', 'Sent', 'Drafts', 'Important', '', 'Trash'];

    const main = tabArr[sidebarIndex];


    useEffect(() => {

        searchTab(main, search)
        
    }, [search]);

    const updateProfile = async () => {

        try {

            setLoading(true)

            const formData = new FormData();

            formData.append('email', user.email)

            formData.append('opassword', user.pass)

            if (!page) {

                if (password) formData.append('password', password);

                if (altEmail) formData.append('alt_email', altEmail);

                if (username) formData.append('name', username)

                if (image) formData.append('dp', image)

                formData.append('_method', "PATCH")

                const res = await axios.post('/update/profile', formData, {
                    'headers': {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    }
                })

                setLoading(false)

                if (res.data?.code === 0) {
                    localStorage.setItem('token', JSON.stringify({
                        ...user,
                        dp: res.data?.user?.img
                    }))

                    setModal(false)

                    setToggle(false)

                    toast.success('Profile Updated Successfully')

                    setDp(res.data?.img)

                } else {
                    toast.success('Verification Code Successfully Sent')
                    setPage(1)
                }

            } else {

                setLoading(true)

                formData.append('pin', pin)

                const { data } = await axios.post('/verify/pin', formData)

                localStorage.setItem('token', JSON.stringify({
                    ...user,
                    alt_email: data?.user.alt_email,
                    pass: data?.user.password,
                    name: data?.user.name,
                    dp: data?.user?.img
                }))

                setPage(0)

                setModal(false)

                setToggle(false)

                toast.success('Profile Updated Successfully')

                setDp(data?.user.img)

                setLoading(false)

            }


        } catch (err) {
            toast.error(err?.response?.data?.message || 'Error Updating Profile')
            setLoading(false)
        }
    }

    return (
        <div className='border-b border-[#f2f2f2] fixed bd1:w-[calc(100%-60px)] w-[80%] h-fit z-10 bg-[#fff]'>

            <Modal open={comeSoon} onClose={() => setComSoon(false)}>
                <div className='w-[80%] h-[80%] bg-[#fff] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg shadow-lg flex flex-col items-center justify-center relative'>
                    <div className="absolute top-[10px] right-[10px] flex items-center justify-end w-full">
                        <IconButton onClick={() => setComSoon(false)}>
                            <MdClose size={30} color='#121212' />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='font-aeonikmedium text-2xl text-center'>Coming Soon</h1>
                        <p className='font-aeonikregular text-sm text-[#919BA7]'>This feature is under development</p></div>
                </div>

            </Modal>


            <Modal
                open={modal}
                sx={{
                    "&& .MuiBackdrop-root": {
                        backdropFilter: "blur(5px)",
                        width: "calc(100% - 8px)",
                    },
                }}
                onClose={() => setModal(false)}
                className="overflow-y-scroll overflow-x-hidden cusscroller flex justify-center"
            >
                <Box
                    className="smd:!w-full h-fit m2:px-[2px]"
                    sx={{
                        minWidth: 300,
                        width: "70%",
                        maxWidth: 800,
                        borderRadius: 6,
                        outline: "none",
                        p: 4,
                        position: "relative",
                        margin: "auto",
                    }}
                >
                    <div className="py-4 px-6 bg-white -mb-[1px] rounded-t-[.9rem]">
                        <div className="mb-2 flex items-start justify-between">
                            <div>
                                <h2 className="font-[500] text-[rgb(32,33,36)] text-[1.55rem] !font-aeonikregular 3mdd:text-[1.25rem]">
                                    Update Profile
                                </h2>
                                <span className="text-[rgb(69,70,73)] !font-aeonikregular font-[500] text-[14px]">
                                    Update your profile details
                                </span>
                            </div>
                            <Tooltip title="Close" arrow>
                                <IconButton size={"medium"} onClick={() => setModal(false)}>
                                    <MdClose
                                        size={20}
                                        color={"rgb(32,33,36)"}
                                        className="cursor-pointer"
                                    />
                                </IconButton></Tooltip>
                        </div>

                        <SwipeableViews index={page}>
                            <TabPanel value={page} index={0}>
                                <div className="py-3">
                                    <div className="flex text-[#565656] items-center justify-between">
                                        <label className="text-[#565656] font-aeonikregular mb-2 font-[600]">
                                            Profile
                                        </label>

                                    </div>
                                    <div className="flex w-full justify-center flex-col item-center ">
                                        <Avatar
                                            onClick={() => {
                                                document.querySelector('.profile')?.click()
                                            }}
                                            sx={{
                                                width: 125,
                                                borderRadius: "50%",
                                                height: 125,
                                                margin: 'auto',
                                                color: "#fff",
                                                cursor: 'pointer',
                                                backgroundColor: "#fff",
                                            }}
                                            src={image ? URL.createObjectURL(image) : dp || picture}
                                            variant="rounded"
                                        >
                                            {user?.name ? String(user.name.charAt(0)).toUpperCase() : ''}
                                        </Avatar>
                                        <span className="text-[#565656] font-aeonikbold mb-2 block text-center mt-2 w-full font-[600]">Click to update</span>
                                        <input type="file" accept="image/*" className="profile !hidden" onChange={(e) => {
                                            e.preventDefault()

                                            setImage(e.target.files[0])

                                        }} />
                                    </div>
                                </div>

                                <div className="py-3">
                                    <div className="flex text-[#565656] items-center justify-between">
                                        <label className="text-[#565656] font-aeonikregular mb-2 font-[600]">
                                            Username
                                        </label>


                                    </div>
                                    <div className="flex justify-center item-center ">
                                        {/* add in inputs */}
                                        <TextField
                                            className="bg-[white] !font-aeonikregular"
                                            sx={{
                                                "& .Mui-focused.MuiFormLabel-root": {
                                                    color: "#199976",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `#199976 !important`,
                                                },
                                            }}
                                            value={username}
                                            fullWidth
                                            placeholder={'Username'}
                                            name="username"
                                            onChange={(
                                                e
                                            ) => setUsername(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="py-3">
                                    <div className="flex text-[#565656] items-center justify-between">
                                        <label className="text-[#565656] font-aeonikregular mb-2 font-[600]">
                                            Update password
                                        </label>

                                    </div>
                                    <div className="flex justify-center item-center ">
                                        {/* add in inputs */}
                                        <TextField
                                            type={viewPass ? "text" : "password"}
                                            className="bg-[white] !font-aeonikregular"
                                            sx={{
                                                "& .Mui-focused.MuiFormLabel-root": {
                                                    color: "#199976",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `#199976 !important`,
                                                },
                                            }}
                                            value={password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <div onClick={() => setViewPass(!viewPass)}>{viewPass ? <FaRegEyeSlash size={20} className="cursor-pointer" color={"#7A7A9D"} /> : <FaRegEye size={20} className="cursor-pointer" color={"#7A7A9D"} />}</div>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            placeholder={'Password'}
                                            name="password"
                                            onChange={(
                                                e
                                            ) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="py-3">
                                    <div className="flex text-[#565656] items-center justify-between">
                                        <label className="text-[#565656] font-aeonikregular mb-2 font-[600]">
                                            Alternative Email
                                        </label>


                                    </div>
                                    <div className="flex justify-center item-center ">
                                        {/* add in inputs */}
                                        <TextField
                                            className="bg-[white] !font-aeonikregular"
                                            sx={{
                                                "& .Mui-focused.MuiFormLabel-root": {
                                                    color: "#199976",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `#199976 !important`,
                                                },
                                            }}
                                            value={altEmail}
                                            fullWidth
                                            placeholder={'Alternative Email'}
                                            name="altemail"
                                            onChange={(
                                                e
                                            ) => setAltEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={page} index={1}>
                                <div className="py-3">
                                    <div className="flex text-[#565656] items-center justify-between">
                                        <label className="text-[#565656] mb-2 font-[600]">
                                            Enter Verification Code
                                        </label>

                                        <IconButton
                                            onClick={() =>
                                                setPinVisibility(!pinsVisibility)
                                            }
                                            size={"medium"}
                                        >
                                            {pinsVisibility ? (
                                                <MdOutlineVisibility size={23} />
                                            ) : (
                                                <MdOutlineVisibilityOff size={23} />
                                            )}
                                        </IconButton>
                                    </div>
                                    <div className="flex justify-center item-center ">
                                        <PinField
                                            type={!pinsVisibility ? "text" : "password"}
                                            length={5}
                                            onComplete={(e) => setPin(e)}
                                            className="font-[inherit] 2usmm:w-[3rem] 2usmm:h-[3rem] 2usmm:text-[1.5rem] 2usmm:!justify-start outline-none border border-[#d3d3d3] h-[4rem] text-center transition-all text-[2rem] focus:border-[#121212] w-[4rem] rounded-[.5rem]  my-3 mx-auto"
                                            validate={/^[0-9]$/}
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                        </SwipeableViews>
                    </div>

                    <div className="bg-[#ffffff] flex justify-center items-center rounded-b-[.9rem] px-6 py-4">
                        <div className="flex items-center">
                            <Button
                                onClick={updateProfile}
                                className="!py-2 !font-bold !min-w-[220px] !text-[16px] !px-3 !flex !font-aeonikbold !items-center !text-white !fill-white !bg-[#199976] !normal-case !border !border-solid !border-[rgb(218,220,224)] !transition-all !delay-500 hover:!text-[#f0f0f0] !rounded-lg"
                            >
                                {isLoading ? (
                                    <>
                                        <div className="mr-3 h-[20px] text-[#fff]">
                                            <CircularProgress
                                                color={"inherit"}
                                                className="!w-[20px] !h-[20px]"
                                            />
                                        </div>{" "}
                                        <span>Just a Sec...</span>
                                    </>
                                ) : (
                                    <>Update</>
                                )}
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>


            <div className='py-5 px-5 flex items-center relative justify-between'>
                <div className="flex bd2:hidden items-center"><IconButton className="ml-2 !hidden bd1:!block" size="middle" onClick={() => setExpand(!expand)}>
                    {expand ? <MdDehaze size={20} className='cursor-pointer text-[rgb(66,84,102)]' /> : <MdClose size={20} className='cursor-pointer text-[rgb(66,84,102)]' />}</IconButton>
                    <h1 className='font-aeonikmedium text-sm'>Welcome {user?.name || user.email},</h1></div>


                <TextField
                    className="bg-[white] !w-[50%] smd:!w-full bd2:!w-[80%] !font-aeonikregular"
                    sx={{
                        "& .MuiInputBase-input": {
                            padding: '13px',
                            paddingLeft: '4px',
                            paddingRight: '4px'
                        },

                        "& .Mui-focused.MuiFormLabel-root": {
                            color: "#199976",

                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: `#199976 !important`,
                        },
                    }}
                    placeholder='Search mail' value={search}
                    fullWidth
                    name="search"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CiSearch className="bd2:hidden" size={20} color={"#7A7A9D"} />
                                <IconButton className="ml-2 !hidden bd2:!block" size="small" onClick={() => setExpand(!expand)}>
                                    {expand ? <MdDehaze size={20} className='cursor-pointer text-[rgb(66,84,102)]' /> : <MdClose size={20} className='cursor-pointer text-[rgb(66,84,102)]' />}</IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <div onClick={() => setToggle(!toggle)} className='hidden smd:block items-center space-x-2 cursor-pointer'>
                                    <span className='bg-[#F2F2F2] h-7 w-7 items-center justify-center flex rounded-full'>
                                        <PiUserBold color='#292D32' />
                                    </span>
                                </div>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(
                        e
                    ) => setSearch(e.target.value)}

                />
                <div onClick={() => setToggle(!toggle)} className='flex items-center space-x-2 cursor-pointer smd:hidden'>
                    <span className='bg-[#F2F2F2] h-7 w-7 items-center justify-center flex rounded-full'>
                        <PiUserBold color='#292D32' />
                    </span>
                    {toggle ? <BiChevronDown color='#808080' size={20} /> : <BiChevronUp color='#292D32' size={20} />}
                </div>

                {toggle && <Box className="!shadow-md max-w-[250px]" sx={{ width: '100%', bgcolor: 'background.paper', position: 'absolute', right: 0, top: 80 }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                            <ListItem onClick={() => setModal(true)} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <CgProfile size={20} />
                                    </ListItemIcon>
                                    <ListItemText sx={{
                                        ".MuiListItemText-primary": {
                                            fontFamily: 'AeonikRegular'
                                        }
                                    }} primary="Update Profile" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={() => {
                                localStorage.removeItem('token')
                                navigator('/')
                            }}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <BiLogOutCircle size={20} />
                                    </ListItemIcon>
                                    <ListItemText sx={{
                                        ".MuiListItemText-primary": {
                                            fontFamily: 'AeonikRegular'
                                        }
                                    }} primary="Logout" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                </Box>}
            </div>
        </div>
    )
}

export default TopBar