import React, { useContext, useEffect, useState } from 'react';

import { IoDocumentTextOutline, IoOptionsOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { BiBold, BiItalic, BiUnderline, BiAlignMiddle, BiAlignLeft, BiAlignRight } from "react-icons/bi";
import { Button, CircularProgress, IconButton, ListItem, ListItemIcon, Tooltip, List, ListItemButton, Box, ListItemText } from '@mui/material';
import { MailContext } from '../../contexts/MailContext';
import { Editor, RichUtils } from 'draft-js'
import 'draft-js/dist/Draft.css';
import { MdAttachment } from 'react-icons/md'
import toast from 'react-hot-toast';

function MailDescription({ documents, removeDocument, onSend, addDocument, loading }) {

    const { setComSoon, message, setMessage, docs } = useContext(MailContext)

    const [toggle, setToggle] = useState(false);

    const editorRef = React.useRef(null)


    const [uploadedDocs, setUploadedDocs] = useState([
        ...docs
    ]);

    const [alignment, setAlignment] = useState('left')

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };


    const handleDocumentUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > 31_457_280) {
            toast.error("File Size Can not be greater than 30mb");
            return;
        }

        const allowedType = ['png', 'jpeg', 'png','pdf','doc','docx','txt','xls','xlsx','ppt','pptx','zip','rar','jpg','svg']

        const type = file.name.split('.').pop().toLowerCase();

        if (!allowedType.includes(type)) {
            toast.error("File type not supported");
            return;
        }

        const name = file.name;
        const sizeInBytes = file.size;
        const extension = name.split('.').pop();

        const temporaryDocData = {
            name: name.substring(0, name.lastIndexOf('.')),
            size: formatBytes(sizeInBytes),
            extension,
        };

        // Update the local state
        setUploadedDocs(prev => [...prev, temporaryDocData]);

        // Continue to send the file to the parent component
        addDocument(file);
    };

    const removeUploadedDoc = (index) => {
        setUploadedDocs(prevDocs => prevDocs.filter((_, docIndex) => docIndex !== index));
    };

    const handleFocus = () => {
        editorRef.current.focus()
    }

    useEffect(() => {
        editorRef.current.onClick = (e) => {
            e.stopPropagation()
        }
    }, [])
    
    // Placeholder functions for the footer functionalities
    const handleBoldClick = () => { 
        setMessage(
            RichUtils.toggleInlineStyle(message, 'BOLD')
        )
        
    };
    const handleItalicClick = () => {
        setMessage(
            RichUtils.toggleInlineStyle(message, 'ITALIC')
        )
    };

    const handleUnderlineClick = () => {
        setMessage(
            RichUtils.toggleInlineStyle(message, 'UNDERLINE')
        )
    };


    const handleAlignLeft = () => {
        setAlignment('left')
    };

    const handleAlignCenter = () => {
        if (document.body.offsetWidth < 461) {
            setToggle(!toggle)
            return;
        }
        setAlignment('center')
    };
    const handleAlignRight = () => { 
        setAlignment('right')
    };

    

    return (
        <div className="flex flex-col space-y-4 mt-4">

            {/* <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="resize-none border-t px-5 py-4 h-60 overflow-y-auto outline-none font-aeonikregular text-sm"
            /> */}

            <div onClick={() => handleFocus()} className="px-5 min-h-[100px]">
                <Editor textAlignment={alignment} preserveSelectionOnBlur={true} stripPastedStyles={true} ref={editorRef} editorState={message} placeholder="Type your message here..." onChange={setMessage} />
            </div>
                        

            <div className="flex flex-wrap mx-5">
                {[...uploadedDocs].map((doc, index) => (
                    <span key={index} className="bg-[#F2F2F2] py-2 px-4 rounded-sm m-1 relative flex items-center space-x-2">
                        <IoDocumentTextOutline className="text-xl" />
                        <p className="font-aeonikregular text-sm text-[#425466]">{doc?.name || (doc).split('/')[(doc).split('/').length - 1]}.{doc?.extension || ""}</p>
                        <span className="text-[#969696]">·</span>
                        <span>{doc.size}</span>
                        <button onClick={() => index < documents.length ? removeDocument(index) : removeUploadedDoc(index - documents.length)} className="ml-2">
                            <AiOutlineClose />
                        </button>
                    </span>
                ))}
            </div>

            <div className="flex items-center self-end w-1/2 mt-2 px-5 pt-4  justify-between z-[1]">

               <Tooltip title="Add attachments"> <IconButton size={'medium'} onClick={() => {
                    document.getElementById('documentUploader')?.click()
                    // setComSoon(true)
                    }}>
                    <input type="file" onChange={handleDocumentUpload} style={{ display: 'none !important' }} className="!hidden" id="documentUploader" />
                    <MdAttachment color='#808080' size={20} />
                </IconButton></Tooltip>

            <div className="relative w-fit h-fit">
                <Tooltip title={"Options"} arrow>
                <IconButton size={'medium'} style={{
                    backgroundColor: toggle ? '#F2F2F2' : '',
                }} onClick={() => setToggle(!toggle)}><IoOptionsOutline color='#808080' size={20} /></IconButton></Tooltip> 

                {toggle && <Box className="!shadow-md w-[145px]" sx={{ bgcolor: 'background.paper', position: 'absolute', right: 0, bottom: '102%' }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                                <ListItem onClick={handleBoldClick} disablePadding>

                                    <ListItemButton>
                                        <ListItemIcon sx={{
                                            minWidth: '36px'
                                        }}>
                                            <BiBold color='#808080' size={20} />
                                        </ListItemIcon>
                                        <ListItemText sx={{
                                            ".MuiListItemText-primary": {
                                                fontFamily: 'AeonikRegular',
                                                fontSize: '13px'
                                            }
                                        }} primary="Bold" />
                                    </ListItemButton>

                                </ListItem>

                                
                                <ListItem onClick={handleItalicClick} disablePadding>

                                    <ListItemButton>
                                        <ListItemIcon sx={{
                                            minWidth: '36px'
                                        }}>
                                            <BiItalic color='#808080' size={20} />
                                        </ListItemIcon>
                                        <ListItemText sx={{
                                            ".MuiListItemText-primary": {
                                                fontFamily: 'AeonikRegular',
                                                fontSize: '13px'
                                            }
                                        }} primary="Italic" />
                                    </ListItemButton>

                                </ListItem>

                                <ListItem onClick={handleUnderlineClick} disablePadding>
                                   

                                <ListItemButton>
                                    <ListItemIcon sx={{
                                        minWidth: '36px'
                                    }}>
                                        <BiUnderline color='#808080' size={20} />
                                    </ListItemIcon>
                                    <ListItemText sx={{
                                        ".MuiListItemText-primary": {
                                            fontFamily: 'AeonikRegular',
                                            fontSize: '13px'
                                        }
                                    }} primary="Underline" />
                                </ListItemButton>
                                
                            </ListItem>
                            <ListItem onClick={handleAlignLeft} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon sx={{
                                        minWidth: '36px'
                                    }}>
                                        <BiAlignLeft color='#808080' size={20} />
                                    </ListItemIcon>
                                    <ListItemText sx={{
                                        ".MuiListItemText-primary": {
                                            fontFamily: 'AeonikRegular',
                                            fontSize: '13px'
                                        }
                                    }} primary="Align Left" />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding onClick={() => setAlignment('center')}>
                                <ListItemButton>
                                    <ListItemIcon sx={{
                                        minWidth: '36px'
                                    }}>
                                        <BiAlignMiddle color='#808080' size={20} />
                                    </ListItemIcon>
                                    <ListItemText sx={{
                                        ".MuiListItemText-primary": {
                                            fontFamily: 'AeonikRegular',
                                            fontSize: '13px'
                                        }
                                    }} primary="Align Center" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={handleAlignRight}>
                                <ListItemButton>
                                    <ListItemIcon sx={{
                                        minWidth: '36px'
                                    }}>
                                        <BiAlignRight color='#808080' size={20} />
                                    </ListItemIcon>
                                    <ListItemText sx={{
                                        ".MuiListItemText-primary": {
                                            fontFamily: 'AeonikRegular',
                                            fontSize: '13px'
                                        }
                                    }} primary="Align Right" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                </Box>}
            </div>

                <Button
                    onClick={() => onSend()}
                    className="!bg-[#199976] !text-white !py-3 !px-5 !rounded-md !font-aeonikmedium !normal-case !text-sm"
                >

                    {(Boolean(loading) && documents.length) ? (
                        <>
                            <div className="mr-3 h-[20px] text-[#fff]">
                                <CircularProgress
                                    color={"inherit"}
                                    className="!w-[20px] ml-[2px] !h-[20px]"
                                />
                            </div>{" "}
                            <span>{`${loading}% Sent`}</span>
                        </>
                    ) : (
                        <>Send</>
                    )}
                </Button>
            </div>
        </div>
    );
}

export default MailDescription;
