import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const navigator = useNavigate()

    const [selected, setSelected] = useState(0)

    const [modal, setModal] = useState(false);

    const [expand, setExpand] = useState(false);

    const [mobileView, setMobileView] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigator('/')
        }
    }, [navigator])

    return (<AuthContext.Provider value={{
        isloggedin: localStorage.getItem('token') ? true : false,
        sidebarIndex: selected,
        setSidebarIndex: (index) => setSelected(index),
        openModal: (state) => setModal(state),
        modal,
        expand,
        setExpand, mobileView, setMobileView,
        logout: () => {
            localStorage.removeItem('token')
            navigator('/')
        }
    }}>{children}</AuthContext.Provider>)
}