import { EditorState } from 'draft-js';
import { createContext, useState, useEffect } from 'react'


export const MailContext = createContext({})

export const MailProvider = ({ children }) => {

    const [modal, setMailModal] = useState(false);

    const [subject, setSubject] = useState('');

    const [docs, setDocs] = useState([]);

    const [draftId, setDraftId] = useState("");

    const [selectedEmail, setSelectedEmail] = useState(null);

    const [comeSoon, setComSoon] = useState(false);

    const [reply, setReply] = useState('');

    const [message, setMessage] = useState(
        () => EditorState.createEmpty(),
    );

    const [search, setSearch] = useState('');

    const [emails, setEmails] = useState([]);

    return (
        <MailContext.Provider value={{
            modal,
            setMailModal,
            subject,
            setComSoon,
            comeSoon,
            setDocs, selectedEmail, setSelectedEmail,
            setSubject,
            message,
            setMessage,
            emails,
            setEmails,
            reply,
            setReply,
            search, setSearch, setDraftId, draftId, docs
        }}>{children}</MailContext.Provider>
    )
}
