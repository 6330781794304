import React, { useContext } from 'react'
import Mailbox from './Emails/MailBox';
import parse from "html-react-parser";
import user from '../../Assets/user.png'
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from '../contexts/AuthContext';
import validator from 'validator'

function Content() {

    const { inboxData, sentData, trashData, draftData, importData } = useContext(DataContext)
   
    const { sidebarIndex } = useContext(AuthContext)

    const datas = [inboxData, sentData, draftData, importData, , trashData];

    let main = datas[sidebarIndex];


    return (
        <div className="h-full">
            <Mailbox emails={main?.length ? main.map((mail, id) => ({
                id: id,
                profileIcon: mail?.img || user,
                sender: mail.fromname,
                attachments: mail?.attachments || [],
                messageIndex: mail.messageIndex,
                senderemail: mail.from,
                to: mail?.to,
                subject: mail.subject,
                time: mail.time,
                important: mail.important,
                seen: mail.seen,
                Date: mail.Date,
                folder: mail?.folder,
                message: mail?.message || "<p></p>",
                mailText: mail.messageText,
                mailHtml: parse(String(mail.message).replace(/<([^<>]+)>/g, function (match, email) {
                   return validator.isEmail(email) ? email : match;
                }).replaceAll('Content-Type: text/plain; charset="UTF-8"', '').replaceAll('Content-Type: text/html; charset="UTF-8"', ''))
            })) : []} />
        </div>
    )
}

export default Content