import { useEffect, useState } from 'react'
import rlogo from '../../Assets/rlogo.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Button, CircularProgress, InputAdornment, TextField } from '@mui/material'
import { MdEmail, MdPassword } from 'react-icons/md'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'

function LoginSetup() {

    const navigator = useNavigate()

    const txt = {
        "& .Mui-focused.MuiFormLabel-root": {
            color: "#199976",
        },
        "& .MuiInputLabel-root": {
            fontWeight: "600",
            color: "#777",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline, .MuiInput-underline::after":
        {
            borderColor: `#199976 !important`,
        }
    }

    const [email, setEmail] = useState('')

    const [pass, setPass] = useState('');

    const [loading, setLoading] = useState(false)

    const [viewPass, setViewPass] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigator('/account/mail')
        }
    }, [navigator])

    const onSubmit = async () => {

        if (loading) return;

        if (!email || !pass) return toast.error('Please fill all the fields');

        try {

            setLoading(true)

        const { data } = await axios.post('/retrieve/mails', {
                email: email,
                password: pass
            }, {
            headers: {
                'Content-Type': 'application/json'
            },
        })

        toast.success('User Successfully Logged In')

         localStorage.setItem('token', JSON.stringify({ email: data.user.email, pass: data.user.password, dp: data.user.img, alt_email: data.user.alt_email, name: data.user.name }))

        axios.post('/trash/delete', {
            email: email,
            password: pass
        });

         navigator('/account/mail')

        } catch (error) {
            setLoading(false)
            toast.error(error.response?.data?.message || "Something went wrong, please try again")
        }

    }

    return (
        <div>
            <div className='flex flex-col items-center justify-center md:mt-32 mt-20 md:mx-0 mx-10 md:w-[100%]'>
                <img src={rlogo} alt='Mail' className='h-6 w-7' />
                <h1 className='font-aeonikmedium text-2xl mt-4'>Welcome back!</h1>
                <p className='text-[#27272E] font-aeonikregular text-sm mt-2 text-center'>Welcome to Qsyrys Mail 👋 Stay Connected live better</p>

                <div className='mt-14 flex-col flex space-y-3'>
                    
                    <label className='font-aeonikregular text-sm text-[#425466]'>E-mail</label>

                    <TextField
                        value={email}
                        sx={txt}
                        onChange={(e) => setEmail(e.target.value)}
                        inputMode="email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdEmail size={20} color={"#7A7A9D"} />
                                </InputAdornment>
                            ),
                        }}
                        placeholder='Type in your work e-mail in this input box' className='bg-[#EDF2F7] rounded-lg py-4 px-4 font-aeonikregular text-xs md:w-96 w-80 outline-none' />
                </div>
                <div className='mt-8 flex-col flex space-y-3'>
                    <label className='font-aeonikregular text-sm text-[#425466]'>Password</label>

                    <TextField
                        type={viewPass ? "text" : "password"}
                        value={pass}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdPassword size={20} color={"#7A7A9D"} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div onClick={() => setViewPass(!viewPass)}>{viewPass ? <FaRegEyeSlash size={20} className="cursor-pointer" color={"#7A7A9D"} /> : <FaRegEye size={20} className="cursor-pointer" color={"#7A7A9D"} />}</div>
                                </InputAdornment>
                            ),
                        }}
                        sx={txt}
                        onChange={(e) => setPass(e.target.value)}
                        placeholder='Type your password' className='bg-[#EDF2F7] rounded-lg py-4 px-4 font-aeonikregular text-xs md:w-96 w-80 outline-none' />

                    <p className='text-[#7A7A9D] font-aeonikregular text-xs mt-2'>Forgot your password ? Reset now</p>
                </div>
                
                <Button onClick={onSubmit} className='!mt-10 !bg-[#199976] !py-4 !items-center !justify-center !flex md:!w-96 !cursor-pointer !w-80 !rounded-lg !font-aeonikmedium !normal-case !text-sm !text-white'>
                        
                        {loading ? (
                                    <>
                                        <div className="mr-3 h-[20px] text-[#fff]">
                                            <CircularProgress
                                                color={"inherit"}
                                                className="!w-[20px] !h-[20px]"
                                            />
                                        </div>{" "}
                                        <span>Just a Sec...</span>
                                    </>
                                ) : (
                                    <>Sign In</>
                                )}
                    </Button>

                    
            </div>
        </div>
    )
}

export default LoginSetup