// EmailItem.js
import React from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { List, ListItemButton, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';

function EmailItem({ em, onSelect, isSelected }) {

  const { sidebarIndex } = React.useContext(AuthContext)


  return (
    <ListItem onClick={onSelect} className={`cursor-pointer ${isSelected ? '!bg-[#F2F2F2]' : 'hover:!bg-[#F2F2F2]'}`} disablePadding>
      <ListItemButton style={{
        opacity: sidebarIndex === 0 && Number(em?.seen) ? 0.66 : 1
      }} className='!flex !items-start !p-3'>
        <img src={em?.profileIcon} alt="Profile" className='rounded-full h-8 w-8 bd2:h-[45px] bd2:w-[45px] mr-2' />
        <div className='w-[80%] bd2:w-[calc(100%-53px)]'>
          <div className='flex items-center justify-between'>
            <p className="font-aeonikmedium text-base min-w-[70%] max-w-[70%] truncate">{em.sender}</p>
            <span className='text-xs font-aeonikregular'>{em.time}</span>
          </div>
          <div className='flex flex-col'>
            <span className='font-aeonikregular text-sm text-[#4E4E4E] max-w-[85%] truncate'>{em.subject}</span>
            <p className="font-aeonikregular text-xs text-[#969696] line-clamp-2">{em.mailText}</p>
          </div>
        </div>
        </ListItemButton>
    </ListItem>
  );
}

export default EmailItem;