import React from 'react'
import LoginSetup from '../Component/AuthenticationComponent/LoginSetup'

function Login() {
  return (
    <div>
      <LoginSetup/>
    </div>
  )
}

export default Login