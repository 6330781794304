import { CircularProgress } from '@mui/material'
import rlogo from '../../Assets/rlogo.png'

const Loader = () => {
    return (
        <div className="loader flex min-h-screen justify-center flex-col items-center">
            <div className='flex items-center space-x-2'>
                <img src={rlogo} alt='Resident' className='h-9 w-10' />
                <h1 className='font-aeonikmedium text-xl'>Resident</h1>
            </div>
            <CircularProgress size={40} color="inherit" className="text-[#199976] mt-9" />
        </div>
    )
}

export default Loader