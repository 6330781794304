import React, { useContext, useState } from 'react'
import rlogo from '../../Assets/rlogo.png'
import { AiOutlinePlus } from "react-icons/ai";
import { HiOutlineInboxArrowDown } from "react-icons/hi2";
import { VscSend } from "react-icons/vsc";
import { IoDocumentTextOutline } from "react-icons/io5";
import { CiStar } from "react-icons/ci";
import { BsFolder } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import { AuthContext } from '../contexts/AuthContext';
import { NumericFormat } from "react-number-format";
import { DataContext } from '../contexts/DataContext';
import { Button, IconButton, Tooltip } from "@mui/material"
import { MailContext } from '../contexts/MailContext';
import { MdClose, MdDehaze } from 'react-icons/md';

function SideBar({ openModal }) {

    const { setSidebarIndex, sidebarIndex, expand, setExpand } = useContext(AuthContext)

    const user = JSON.parse(localStorage.getItem('token'));

    const { setSentData, inboxData, setDraftData, setTrashData, setImportData } = useContext(DataContext)

    const { setComSoon } = useContext(MailContext)

    return (
        <div className='h-screen border-r border-[#F2F2F2] py-6'>
            <div className='flex items-center justify-between mx-4'>
                <div className='flex items-center justify-between w-full'>
                    <div style={{
                        marginTop: !expand ? 0 : 5
                    }} className="flex items-center space-x-2"><img src={rlogo} alt='Resident' className='h-5 w-6' />
                    {!expand && <h1 className='font-aeonikmedium text-base'>Resident Mail</h1>}</div>

                    {!expand && <IconButton className="ml-2 !hidden bd1:!block" size="middle" onClick={() => setExpand(!expand)}>
                        {expand ? <MdDehaze size={20} className='cursor-pointer text-[rgb(66,84,102)]' /> : <MdClose size={20} className='cursor-pointer text-[rgb(66,84,102)]' />}</IconButton>}
                </div>

            </div>
            <div style={{
                opacity: !expand ? 1 : 0,
            }} className="mt-5">
                <h1 className='font-aeonikmedium hidden bd2:block text-center text-sm'>Welcome {user?.name || user.email},</h1>
            </div>
            <div className='mt-4'>
                <Tooltip title={"Create Mail"} arrow>
                <Button sx={{
                    width: !expand ? '70% !important' : '80% !important'
                }} onClick={openModal} className={`!bg-[#199976] !py-3 !items-center !justify-center !flex !rounded-lg !space-x-2 !cursor-pointer !font-aeonikmedium !m-auto !normal-case !min-w-fit !text-sm !text-white`}>
                    <AiOutlinePlus color='#fff' style={{
                        marginRight: !expand ? 8 : 0
                    }} strokeWidth={2} />
                    {!expand && "Compose"}
                </Button></Tooltip>
                {/*All SideBar Options to Select and Load up Views*/}
                <div className='space-y-2 mt-10'>
                    {/*Inbox*/}
                    <div
                        onClick={() => setSidebarIndex(0)}
                        style={{
                            paddingLeft: !expand ? 24 : 12,
                            paddingRight: !expand ? 24 : 12,
                        }}
                        className={sidebarIndex === 0 ?
                            "bg-[#F3FEF3] flex relative items-center justify-between px-6 py-3 border-l-4 border-[#199976] cursor-pointer" :
                            "flex items-center relative justify-between px-6 py-3 cursor-pointer border-transparent border-l-4 hover:bg-[#F3FEF3]"}>
                        {/*Icon and Text*/}
                        <div className='flex items-center space-x-4'>
                            <HiOutlineInboxArrowDown color={sidebarIndex === 0 ? "#199976" : "#425466"} strokeWidth={2} size={22} />
                            {!expand && <h1 className={sidebarIndex === 0 ?
                                "text-sm font-aeonikmedium text-[#199976]" :
                                "text-sm font-aeonikmedium text-[#425466]"}>Inbox</h1>}
                        </div>
                        {/*Icon and Text*/}
                        {/*Badge or Number Notification*/}
                        <span style={{
                            position: !expand ? 'unset' : 'absolute'
                        }} className='bg-[#EFEFF1] rounded-full h-5 inline-flex items-center bd1:absolute bd1:-top-[8px] bd1:right-0 justify-center px-2'>
                            <h1 className='text-xs font-aeonikmedium'>{<NumericFormat
                                thousandSeparator={true}
                                displayType="text"
                                value={inboxData ? inboxData.filter(({ seen }) => !Number(seen)).length : 0}
                            />}</h1>
                        </span>
                        {/*Badge or Number Notification*/}
                    </div>
                    {/*Inbox*/}

                    {/*Sent Mails*/}
                    <div
                        onClick={() => { setSidebarIndex(1); setSentData(sidebarIndex === 1)}}
                        style={{
                            paddingLeft: !expand ? 24 : 12,
                            paddingRight: !expand ? 24 : 12,
                        }}
                        className={sidebarIndex === 1 ?
                            "bg-[#F3FEF3] flex items-center justify-between px-6 py-3 border-l-4 border-[#199976] cursor-pointer" :
                            "flex items-center border-transparent border-l-4 justify-between px-6 py-3 cursor-pointer hover:bg-[#F3FEF3]"}>
                        {/*Icon and Text*/}
                        <div className='flex items-center space-x-4'>
                            <VscSend color={sidebarIndex === 1 ? "#199976" : "#425466"} radius={24} size={22} />
                            {!expand && <h1 className={sidebarIndex === 1 ?
                                "text-sm font-aeonikmedium text-[#199976]" :
                                "text-sm font-aeonikmedium text-[#425466]"}>Sent</h1>}
                        </div>
                        {/*Icon and Text*/}
                        {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
                        <span className='bg-[#EFEFF1] rounded-full h-5 //inline-flex hidden items-center justify-center px-2'>
                            <h1 className='text-xs font-aeonikmedium'>15,689</h1>
                        </span>
                        {/*Badge or Number Notification*/}
                    </div>
                    {/*Sent Mails*/}

                    {/*Draft Mails*/}
                    <div
                        onClick={() => { setSidebarIndex(2); setDraftData(sidebarIndex === 2) }}
                        style={{
                            paddingLeft: !expand ? 24 : 12,
                            paddingRight: !expand ? 24 : 12,
                        }}
                        className={sidebarIndex === 2 ?
                            "bg-[#F3FEF3] flex items-center justify-between px-6 py-3 border-l-4 border-[#199976] cursor-pointer" :
                            "flex items-center border-transparent border-l-4 justify-between px-6 py-3 cursor-pointer hover:bg-[#F3FEF3]"}>
                        {/*Icon and Text*/}
                        <div className='flex items-center space-x-4'>
                            <IoDocumentTextOutline color={sidebarIndex === 2 ? "#199976" : "#425466"} radius={24} size={22} />
                            {!expand && <h1 className={sidebarIndex === 2 ?
                                "text-sm font-aeonikmedium text-[#199976]" :
                                "text-sm font-aeonikmedium text-[#425466]"}>Draft</h1>}
                        </div>
                        {/*Icon and Text*/}
                        {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
                        {/* <span className='bg-[#EFEFF1] rounded-full h-5 inline-flex items-center justify-center px-2'>
                            <h1 className='text-xs font-aeonikmedium'>23</h1>
                        </span> */}
                        {/*Badge or Number Notification*/}
                    </div>
                    {/*Draft Mails*/}

                    {/*Important Mails*/}
                    <div
                        onClick={() => {
                            setSidebarIndex(3)
                            setImportData(sidebarIndex === 3)
                            // setComSoon(true)
                        }}
                        style={{
                            paddingLeft: !expand ? 24 : 12,
                            paddingRight: !expand ? 24 : 12,
                        }}
                        className={sidebarIndex === 3 ?
                            "bg-[#F3FEF3] flex items-center justify-between px-6 py-3 border-l-4 border-[#199976] cursor-pointer" :
                            "flex items-center border-transparent border-l-4 justify-between px-6 py-3 cursor-pointer hover:bg-[#F3FEF3]"}>
                        {/*Icon and Text*/}
                        <div className='flex items-center space-x-4'>
                            <CiStar color={sidebarIndex === 3 ? "#199976" : "#425466"} radius={24} strokeWidth={1} size={22} />
                            {!expand && <h1 className={sidebarIndex === 3 ?
                                "text-sm font-aeonikmedium text-[#199976]" :
                                "text-sm font-aeonikmedium text-[#425466]"}>Important</h1>}
                        </div>
                        {/*Icon and Text*/}
                        {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
                        <span className='bg-[#EFEFF1] rounded-full h-5 //inline-flex hidden items-center justify-center px-2'>
                            <h1 className='text-xs font-aeonikmedium'>23</h1>
                        </span>
                        {/*Badge or Number Notification*/}
                    </div>
                    {/*Important Mails*/}

                    {/*Group Mails*/}
                    <div
                        onClick={() => {
                            // setSidebarIndex(4)
                            setComSoon(true)
                        }}
                        style={{
                            paddingLeft: !expand ? 24 : 12,
                            paddingRight: !expand ? 24 : 12,
                        }}
                        className={sidebarIndex === 4 ?
                            "bg-[#F3FEF3] flex items-center justify-between px-6 py-3 border-l-4 border-[#199976] cursor-pointer" :
                            "flex items-center border-transparent border-l-4 justify-between px-6 py-3 cursor-pointer hover:bg-[#F3FEF3]"}>
                        {/*Icon and Text*/}
                        <div className='flex items-center space-x-4'>
                            <BsFolder color={sidebarIndex === 4 ? "#199976" : "#425466"} radius={24} size={22} />
                            {!expand && <h1 className={sidebarIndex === 4 ?
                                "text-sm font-aeonikmedium text-[#199976]" :
                                "text-sm font-aeonikmedium text-[#425466]"}>Group</h1>}
                        </div>
                        {/*Icon and Text*/}
                        {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
                        <span className='bg-[#EFEFF1] rounded-full h-5 //inline-flex hidden items-center justify-center px-2'>
                            <h1 className='text-xs font-aeonikmedium'>23</h1>
                        </span>
                        {/*Badge or Number Notification*/}
                    </div>
                    {/*Group Mails*/}

                    {/*Trash Mails*/}
                    <div
                        onClick={() => { setSidebarIndex(5); setTrashData(sidebarIndex === 5) }}
                        style={{
                            paddingLeft: !expand ? 24 : 12,
                            paddingRight: !expand ? 24 : 12,
                        }}
                        className={sidebarIndex === 5 ?
                            "bg-[#F3FEF3] flex items-center justify-between px-6 py-3 border-l-4 border-[#199976] cursor-pointer" :
                            "flex items-center border-transparent border-l-4 justify-between px-6 py-3 cursor-pointer hover:bg-[#F3FEF3]"}>
                        {/*Icon and Text*/}
                        <div className='flex items-center space-x-4'>
                            <FiTrash color={sidebarIndex === 5 ? "#199976" : "#425466"} radius={24} size={22} />
                            {!expand && <h1 className={sidebarIndex === 5 ?
                                "text-sm font-aeonikmedium text-[#199976]" :
                                "text-sm font-aeonikmedium  text-[#425466]"}>Trash</h1>}
                        </div>
                        {/*Icon and Text*/}
                        {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
                        <span className='bg-[#EFEFF1] rounded-full h-5 //inline-flex hidden items-center justify-center px-2'>
                            <h1 className='text-xs font-aeonikmedium'>23</h1>
                        </span>
                        {/*Badge or Number Notification*/}
                    </div>
                    {/*Trash Mails*/}
                </div>
                {/*All SideBar Options to Select and Load up Views*/}
            </div>
        </div>
    )
}

export default SideBar