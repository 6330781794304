import React from 'react';

function ErrorPage() {

  return (
    <>
    <div>ErrorPage</div>

    </>
  )
}

export default ErrorPage