import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import ErrorPage from './Pages/ErrorPage'
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import axios from 'axios'
import { AuthProvider } from './Component/contexts/AuthContext'
import { Toaster } from 'react-hot-toast';
import { MailProvider } from './Component/contexts/MailContext'
import { DataProvider } from './Component/contexts/DataContext'

function App() {

  axios.defaults.baseURL = 'https://api.qsyrys.com';

  axios.defaults.timeout = 300000

  return (
      <BrowserRouter>
        <AuthProvider>
          <DataProvider>
          <MailProvider>
          <Routes>
            <Route path='/' element={<Login />}></Route>

            <Route path='/account/mail' element={<Dashboard />}></Route>
            {/*Protected Route    
            <Route path='/services' element={(isloggedin === true ? <Services /> : <Unauthorized />)}></Route>
            <Route path='/admindashboard' element={(isloggedin === true ? <AdminDashboard /> : <Unauthorized />)}></Route>
            */}
            {/*Protected Route*/}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Toaster />
            </MailProvider>
          </DataProvider>
        </AuthProvider>
      </BrowserRouter>
  )
}

export default App